import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEOPage = makeShortcode("SEOPage");
const BenefitsHeadingPara = makeShortcode("BenefitsHeadingPara");
const InformationCard = makeShortcode("InformationCard");
const BenefitsHeadingButton = makeShortcode("BenefitsHeadingButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOPage title="Benefits for Schools" description="Explore how Dynos is beneficial for schools" image="standards" mdxType="SEOPage" />
    <BenefitsHeadingPara heading="Empower your school to learn and create with its own interactive digital content" subHeading="With Dynos, school leaders can manage all the digital content created by teachers without investing in expensive content from big publishers. You’ll be surprised at just how much your students and teachers can do with our powerful built-in features." button="Request a Demo" image="/parents.png" className="parents" mdxType="BenefitsHeadingPara" />
    <InformationCard name="expectancy" question="How likely does the user think they will be successful using the product? What resources do you have?" answer1="Parents are often worried about their child's use of technology. With Dynos' comprehensive digital content, parents can have worry-free safe learning environment." mdxType="InformationCard" />
    <InformationCard name="value" question="How will this product add value or address needs? Which needs is the product addressing?" answer1="With Parent login, parents can easily monitor their child's performance and their engagement on reading and assignments." mdxType="InformationCard" />
    <InformationCard name="cost" question="What do users have to give up to use this product? How can we minimize this cost?" answer1="For a focused learning environment, it is recommended for children to have their own devices at home to interact with the content as well as submit assignments online." answer2="If desired, Dynos can work with the third-party vendors to supply the devices for students at a discounted price." mdxType="InformationCard" />
    <BenefitsHeadingButton heading="Get in touch with us to know more how Dynos can help your school." button="Request a Demo" mdxType="BenefitsHeadingButton" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      